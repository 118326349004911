import React, { useEffect, useState } from "react"
import { SidebarLayout } from "../components/layout"
import { format } from "date-fns"
import { useQueryParam } from "use-query-params"
import { SpecialFrameViewer } from "../components/specialFrameViewer"

export const Entries: React.FC<any> = ({ pageContext }) => {
  const { competition, page, sidebar } = pageContext
  const startDate = format(new Date(competition.start_date), "iii do LLL yyyy")
  const endDate = format(new Date(competition.end_date), "iii do LLL yyyy")
  const [id] = useQueryParam("entryId")
  const [entryId, setEntryId] = useState(id)
  const [frameUrl, setFrameUrl] = useState(page.fileName)

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)

    if (params.has("entryId")) {
      setEntryId(params.get("entryId"))
      const url = frameUrl.split("Tables/")[0]
       if (entryId.includes('htm')) {
          setFrameUrl(url + `Sheets/${entryId}`)
       }else {
               setFrameUrl(url + `Sheets/${entryId}.htm`)
       }
      

    }
  }, [])

  return (
    <SidebarLayout
      sidebar={{
        competitionSlug: pageContext.competitionSlug,
        navigation: sidebar.navigation,
      }}
      competition={competition}
    >
      <div className="px-5">
        <div className="mb-8">
          <h2 className="font-primary font-black text-3xl">
            {competition.title.text}
          </h2>
          <time>
            {startDate} - {endDate}
          </time>
        </div>
        <div style={{ maxHeight: "900px", overflow: "scroll" }}>
          <SpecialFrameViewer frameUrl={frameUrl} />
        </div>
      </div>
    </SidebarLayout>
  )
}

export default Entries
